// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";
import _ExcelApi from "./Cdn/Excel.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";

// Core Server
import _NotificationApi from "./Core/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _HelpApi from "./Core/Help.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Auth/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _ComponentApi from "./Core/Component.api";
import _ApplicantProfileEducationalQualificationApi from "./Core/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Core/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Core/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Core/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Core/ApplicantProfileTrainingSeminarCourse.api";
import _ExamApi from "./Core/Exam.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _PaymentApi from "./Core/Payment.api";
import _CorePaymentHistoryApi from "./Core/PaymentHistory.api";

// Accommodation
import _DashboardApi from "./Accommodation/Dashboard.api";
import _ExportAccommodationApi from "./Accommodation/ExportAccommodation.api";
import _ImportAccommodationApi from "./Accommodation/ImportAccommodation.api";
import _EligibilityCriteriaApi from "./Accommodation/EligibilityCriteria.api";
import _TemplateApi from "./Accommodation/Templates.api";
import _WeekendInformationApi from "./Accommodation/WeekendInformation.api";
import _HolidayInformationApi from "./Accommodation/HolidayInformation.api";
import _PolicyTermsAndConditionApi from "./Accommodation/PolicyTermsAndCondition.api";
import _AdvertisementInformationApi from "./Accommodation/AdvertisementInformation.api";
import _StaffInformationApi from "./Accommodation/StaffInformation.api";
import _AccommodationCategoryApi from "./Accommodation/AccommodationCategory.api";
import _AccommodationInformationApi from "./Accommodation/AccommodationInformation.api";
import _AccommodationBuildingInfo from "./Accommodation/AccommodationBuildingInfo.api";
import _CircularInformationApi from "./Accommodation/CircularInformationApi.api";
import _NoticeInformationApi from "./Accommodation/NoticeInformationApi.api";
import _AccommodationTypeApi from "./Accommodation/AccommodationType.api";
import _RentSetupApi from "./Accommodation/RentSetup.api";
import _CommonConfigurationTypeApi from "./Accommodation/CommonConfigurationType.api";
import _AllotmentApplicationApi from "./Accommodation/AllotmentApplication.api";
import _CheckInOutApi from "./Accommodation/CheckInOut.api";
import _AccommodationDiscountApi from "./Accommodation/AccommodationDiscount.api";
import _IssueLetterApi from "./Accommodation/IssueLetter.api";
import _ReceiveLetterApi from "./Accommodation/ReceiveLetter.api";
import _UploadAttachmentApi from "./Accommodation/UploadAttachment.api";
import _AccommodationPaymentApi from "./Accommodation/Payment.api";
import _RefundPaymentApi from "./Accommodation/RefundPayment.api";
import _BillGenerationApi from "./Accommodation/BillGeneration.api";
import _ReleaseAllotmentApi from "./Accommodation/ReleaseAllotment.api";
import _PaymentConfigurationApi from "./Accommodation/PaymentConfiguration.api";
import _ApplicationRescheduleExtensionSwitchingApi from "./Accommodation/ApplicationRescheduleExtensionSwitching.api";
import _AccommodationDropdownApi from "./Accommodation/AccommodationDropdown.api";

//REPORT
import _ReportAccommodationApi from "./Accommodation/ReportAccommodation.api";

//ACCOMMODATION CMS
import _HomePageApi from "./Accommodation/HomePageApi.api";
import _FilterApi from "./Accommodation/FilterApi.api";
import _BuildingDetailsApi from "./Accommodation/PublicUI/BuildingDetailsApi.api";
import _BookingInformationApi from "./Accommodation/PublicUI/BookingInformationApi.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _WebsocketApi from "./Util/Websocket.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();
export const ExcelApi = new _ExcelApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const ServiceApi = new _ServicesApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();
export const InstituteHeadApi = new _InstituteHeadApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const HelpApi = new _HelpApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const ComponentApi = new _ComponentApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const ExamApi = new _ExamApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const PaymentApi = new _PaymentApi();
export const CorePaymentHistoryApi = new _CorePaymentHistoryApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Util
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const WebsocketApi = new _WebsocketApi();

// Accommodation
export const ExportAccommodationApi = new _ExportAccommodationApi();
export const DashboardApi = new _DashboardApi();
export const ImportAccommodationApi = new _ImportAccommodationApi();
export const EligibilityCriteriaApi = new _EligibilityCriteriaApi();
export const TemplatesApi = new _TemplateApi();
export const WeekendInformationApi = new _WeekendInformationApi();
export const HolidayInformationApi = new _HolidayInformationApi();
export const PolicyTermsAndConditionApi = new _PolicyTermsAndConditionApi();
export const AdvertisementInformationApi = new _AdvertisementInformationApi();
export const StaffInformationApi = new _StaffInformationApi();
export const AccommodationCategoryApi = new _AccommodationCategoryApi();
export const AccommodationInformationApi = new _AccommodationInformationApi();
export const AccommodationBuildingInfoApi = new _AccommodationBuildingInfo();
export const CircularInformationApi = new _CircularInformationApi();
export const NoticeInformationApi = new _NoticeInformationApi();
export const AccommodationTypeApi = new _AccommodationTypeApi();
export const RentSetupApi = new _RentSetupApi();
export const ApplicationRescheduleExtensionSwitchingApi = new _ApplicationRescheduleExtensionSwitchingApi();
export const CommonConfigurationTypeApi = new _CommonConfigurationTypeApi();
export const AllotmentApplicationApi = new _AllotmentApplicationApi();
export const CheckInOutApi = new _CheckInOutApi();
export const AccommodationDiscountApi = new _AccommodationDiscountApi();
export const IssueLetterApi = new _IssueLetterApi();
export const ReceiveLetterApi = new _ReceiveLetterApi();
export const UploadAttachmentApi = new _UploadAttachmentApi();
export const AccommodationPaymentApi = new _AccommodationPaymentApi();
export const RefundPaymentApi = new _RefundPaymentApi();
export const BillGenerationApi = new _BillGenerationApi();
export const ReleaseAllotmentApi = new _ReleaseAllotmentApi();
export const PaymentConfigurationApi = new _PaymentConfigurationApi();
export const ReportAccommodationApi = new _ReportAccommodationApi();
export const AccommodationDropdownApi = new _AccommodationDropdownApi();

//Accommodation CMS
export const HomePageApi = new _HomePageApi();
export const FilterApi = new _FilterApi();
export const BuildingDetailsApi = new _BuildingDetailsApi();
export const BookingInformationApi = new _BookingInformationApi();
