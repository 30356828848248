import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/report`;
const endpoints = {
    getAccommodationReportList: () => `${RESOURCE_ENDPOINT}/accommodation-list`,
    getAccommodationDetailInformationReport: () =>
        `${RESOURCE_ENDPOINT}/accommodation-detail-information`,
    getResidentReportList: () => `${RESOURCE_ENDPOINT}/resident-list`,
    getApplicantInformationReportList: () =>
        `${RESOURCE_ENDPOINT}/applicant-information`,
    getGuestHouseDormitoryRegisterReportList: () =>
        `${RESOURCE_ENDPOINT}/guest-house-or-dormitory-register`,
    getAccommodationRentInfoReportList: () =>
        `${RESOURCE_ENDPOINT}/accommodation-rent-information`,
    getAccommodationSummaryReportList: () =>
        `${RESOURCE_ENDPOINT}/accommodation-summary`,
    getYearlyAccommodationRentSummaryReportList: () =>
        `${RESOURCE_ENDPOINT}/yearly-accommodation-rent-summary`,
    getAccommodationTypeWiseRentSummaryReportList: () =>
        `${RESOURCE_ENDPOINT}/accommodation-type-wise-rent-summery`,
    getRentBillInfo: () => `${RESOURCE_ENDPOINT}/get-rent-bill-report`,
    getCashMemo: () => `${RESOURCE_ENDPOINT}/cash-memo`,
    getPaymentSummeryOnline: () => `${RESOURCE_ENDPOINT}/payment-summery-online`,
};

export default class ReportAccommodationApi {
    public getAccommodationReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAccommodationReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getAccommodationDetailInformationReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAccommodationDetailInformationReport();
        return HttpService.post(url, payload, params, headers);
    };
    public getResidentReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getResidentReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getApplicantInformationReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getApplicantInformationReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getGuestHouseDormitoryRegisterReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getGuestHouseDormitoryRegisterReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getAccommodationRentInfoReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAccommodationRentInfoReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getAccommodationSummaryReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAccommodationSummaryReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getYearlyAccommodationRentSummaryReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getYearlyAccommodationRentSummaryReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getAccommodationTypeWiseRentSummaryReportList = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getAccommodationTypeWiseRentSummaryReportList();
        return HttpService.post(url, payload, params, headers);
    };
    public getRentBillInfo = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getRentBillInfo();
        return HttpService.post(url, payload, params, headers);
    };
    public getCashMemo = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getCashMemo();
        return HttpService.post(url, payload, params, headers);
    };
    public getPaymentSummeryOnline = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getPaymentSummeryOnline();
        return HttpService.post(url, payload, params, headers);
    };
}
