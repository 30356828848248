import React, { createContext, useState } from 'react'

const initialState = {
    accommodationVisitorApplicationId: '',
    visitorAccommodationId: null
};

export const AccommodationContext = createContext<any>(initialState);

export const AccommodationContextProvider = ({ children }) => {
    const [accommodationVisitorApplicationId, setAccommodationVisitorApplicationId] = useState<string>(initialState.accommodationVisitorApplicationId);
    const [visitorAccommodationId, setVisitorAccommodationId] = useState<any>(initialState.visitorAccommodationId);

    const contextValue = {
        accommodationVisitorApplicationId, setAccommodationVisitorApplicationId, visitorAccommodationId, setVisitorAccommodationId
    };

    return (
        <AccommodationContext.Provider value={contextValue}>
            {children}
        </AccommodationContext.Provider>
    );
};

export default AccommodationContextProvider;
