import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/payments`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getPaymentListByApplicationId: (applicationId: any) =>
        `${RESOURCE_ENDPOINT}/payment-list-by-application-id/${applicationId}`,
    getInvoiceById: (id: any) => `${RESOURCE_ENDPOINT}/invoices/${id}`,
    getApplicationByForPayment: (id: any) =>
        `${RESOURCE_ENDPOINT}/getApplicationByForPayment/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    getPaymentsByApplicationId: (applicationId: any) =>
        `${RESOURCE_ENDPOINT}/getPaymentsByApplicationId/${applicationId}`,
    getAllPaymentsByApplicationId: (applicationId: any) =>
        `${RESOURCE_ENDPOINT}/getAllPaymentsByApplicationId/${applicationId}`,
};

export default class AccommodationPaymentApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };
    public getPaymentListByApplicationId = (
        applicationId: any
    ): AxiosPromise<any> => {
        const url = endpoints.getPaymentListByApplicationId(applicationId);
        return HttpService.get(url);
    };
    public getInvoiceById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getInvoiceById(id);
        return HttpService.get(url);
    };
    public getApplicationByForPayment = (id: any): AxiosPromise<any> => {
        const url = endpoints.getApplicationByForPayment(id);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };

    public getPaymentsByApplicationId = (
        applicationId: any
    ): AxiosPromise<any> => {
        const url = endpoints.getPaymentsByApplicationId(applicationId);
        return HttpService.get(url);
    };
    public getAllPaymentsByApplicationId = (
        applicationId: any
    ): AxiosPromise<any> => {
        const url = endpoints.getAllPaymentsByApplicationId(applicationId);
        return HttpService.get(url);
    };
}
