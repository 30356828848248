import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../../constants";
import { HttpService } from "../../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/building-details`;
const endpoints = {
    getByWhere: () => `${RESOURCE_ENDPOINT}`,
    filterAccommodationList: () => `${RESOURCE_ENDPOINT}/filtered-accommodation-list`,
};

export default class BuildingDetailsApi {
    public filterAccommodationList = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.filterAccommodationList();
        return HttpService.get(url, params, headers);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    };
}
