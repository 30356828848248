import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/allotment-application`;
const endpoints = {
    checkAvailibility: () =>
        `${RESOURCE_ENDPOINT}/check-accommodation-availability`,
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getByUUID: (uuid: any) => `${RESOURCE_ENDPOINT}/get-by-uuid/${uuid}`,
    getFineData: (id: any) => `${RESOURCE_ENDPOINT}/getFineData/${id}`,
    getApplicationWithAccommodationAndBuildingInfo: (id: any) =>
        `${RESOURCE_ENDPOINT}/application-with-accommodation-and-building-info/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    confirmApplicationSubmission: (uuid: any) =>
        `${RESOURCE_ENDPOINT}/confirm-application-submission/${uuid}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    allotmentApplicationUserDropdown: () =>
    `${RESOURCE_ENDPOINT}/allotment-application-user-dropdown`,
    getRentInfo: () => `${RESOURCE_ENDPOINT}/getRentInfo`,
    cancelApplication: (id: Number) => `${RESOURCE_ENDPOINT}/cancelApplication/${id}`,
};

export default class AllotmentApplicationApi {
    public checkAvailibility = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.checkAvailibility();
        return HttpService.get(url, params, headers);
    };

    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getByUUID = (uuid: any): AxiosPromise<any> => {
        const url = endpoints.getByUUID(uuid);
        return HttpService.get(url);
    };

    public getFineData = (id: any): AxiosPromise<any> => {
        const url = endpoints.getFineData(id);
        return HttpService.get(url);
    };

    public getApplicationWithAccommodationAndBuildingInfo = (
        id: any
    ): AxiosPromise<any> => {
        const url =
            endpoints.getApplicationWithAccommodationAndBuildingInfo(id);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public confirmApplicationSubmission = (
        uuid: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.confirmApplicationSubmission(uuid);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };
    public allotmentApplicationUserDropdown = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.allotmentApplicationUserDropdown();
        return HttpService.get(url, params, headers);
    };

    public getRentInfo = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getRentInfo();
        return HttpService.post(url, payload, params, headers);
    };

    public cancelApplication = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.cancelApplication(id);
        return HttpService.put(url, payload, params, headers);
    };
}
