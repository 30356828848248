export enum DeviceTypeEnum {
    PHONE = 'Phone',
    TAB = 'Tab',
}

export enum DeviceOSEnum {
    ANDROID = 'Android',
    IPHONE = 'iPhone',
    WINDOWS = 'Windows',
}
