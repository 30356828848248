import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../../constants";
import { HttpService } from "../../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/booking-information`;
const endpoints = {
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    bookingInformation: () => `${RESOURCE_ENDPOINT}`,
    rentInformation: () => `${RESOURCE_ENDPOINT}/rent-information`,
};

export default class BookingInformationApi {
    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public bookingInformation = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bookingInformation();
        return HttpService.get(url, params, headers);
    };
    
    public rentInformation = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.rentInformation();
        return HttpService.get(url, params, headers);
    };
}
