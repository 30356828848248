import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/dashboard`;
const endpoints = {
    statuses: () => `${RESOURCE_ENDPOINT}/status-graphs`,
};

export default class DashboardApi {
    public statuses = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.statuses();
        return HttpService.post(url, payload, params, headers);
    }
}
