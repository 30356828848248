import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/accommodation-dropdown`;
const endpoints = {
    building: () => `${RESOURCE_ENDPOINT}/building`,
    accommodation: () => `${RESOURCE_ENDPOINT}/accommodation`,
    application: () => `${RESOURCE_ENDPOINT}/application`,
    user: () => `${RESOURCE_ENDPOINT}/user`,
    payment: () => `${RESOURCE_ENDPOINT}/payment`,
};

export default class AccommodationDropdownApi {
    public building = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.building();
        return HttpService.get(url, params, headers);
    };

    public accommodation = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.accommodation();
        return HttpService.get(url, params, headers);
    };

    public application = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.application();
        return HttpService.get(url, params, headers);
    };

    public user = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.user();
        return HttpService.get(url, params, headers);
    };
    public payment = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.payment();
        return HttpService.get(url, params, headers);
    };
}
